.OurDoctors{
    cursor: pointer;
	background-color: #ebe9e9;
	color: #28a745;
	padding-top: 20px;
	padding-bottom: 30px;
}
.OurDoctors hr{
    width: 7%;
    background-color: #ffc107;
    padding: 1px;
    border-radius: 10px;
    margin-bottom: 30px;
}
@media only screen and (max-width: 800px) {
    .OurDoctors hr{
        width: 20%;
        background-color: #ffc107;
        padding: 1px;
        border-radius: 10px;
    }
    .OurDoctorSize{
        font-size: 30px;
    }
}