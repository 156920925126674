:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --gray: #efeff0;
}

.button {
  border: var(--blue) 1px solid;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  width: 80%;
  height: 50px;
  font-weight: bold;
}

.button span {
  font-weight: bolder;
}
.button:hover {
  background-color: var(--white);
  color: var(--blue);
}
.line {
  width: 15%;
  background-color: #1e90ff;
  padding: 2px;
  border-radius: 10px;
}
.left {
  float: left;
  margin-right: 25%;
  margin-left: 4%;
}
.right {
  float: left;
  margin-right: 34%;
  margin-left: 12%;
}

/* card area */
.icon {
  width: 30%;
}
.grid {
  border: 1px solid white;
  border-radius: 15px;
  background-color: white;
  padding: 15px;
  height: 370px;
}

.grid:hover {
  border: 1px solid white;
  box-shadow: 5px 10px 5px 10px #ffffff;
}
.bg {
  background-color: var(--gray);
}
/* card area end */
.underline {
  width: 5%;
  background-color: #1e90ff;
  padding: 2px;
  border-radius: 10px;
}
.text-light h3 {
  margin-bottom: 20px;
  text-align: center;
}
.text-light p {
  margin-bottom: 20px;
  padding: 0 30px;
}
@media screen and (max-width: 800px) {
  .text-light p {
    margin: 0 0 20px 0;
    padding: 0 0px;
    letter-spacing: 1px;
    text-align: center;
  }
  .text-light h3 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    word-spacing: 2px;
  }

  .heading {
    font-size: 32px;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .heading2 {
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .heading3 h1 {
    font-size: 25px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  section h2 {
    font-size: 35px;
    letter-spacing: 2px;
  }
  .left {
    float: left;
    margin-right: 25%;
    margin-left: 3%;
  }
  .right {
    float: left;
    margin-right: 35%;
    margin-left: 13%;
  }
  .grid {
    height: 420px;
  }
  .img-card {
    height: 400px !important;
  }
  .img-card2 {
    height: 400px !important;
  }
}

/* img-card area */

.img-card {
  border: #efeff0 1px solid;
  background-color: rgb(228, 226, 226);
  height: 500px;
}
.img-card:hover {
  border: #efeff0 1px solid;
  background-color: rgb(14, 179, 255);
  color: rgb(255, 255, 255);
  box-shadow: 1px 2px 10px gray;
}
.img-card img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  width: 40%;
  border-radius: 50%;
  object-fit: cover;
}
.img-card h3 {
  text-align: center;
  margin-top: 5%;
}
.img-card p {
  text-align: center;
  padding: 10px;
}
.img-card2 {
  border: #efeff0 1px solid;
  background-color: rgb(228, 226, 226);
  height: 500px;
}
.img-card2:hover {
  border: #efeff0 1px solid;
  background-color: rgb(14, 179, 255);
  color: rgb(255, 255, 255);
  box-shadow: 1px 2px 10px gray;
}
.img-card2 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  width: 38%;
  border-radius: 50%;
  object-fit: cover;
}
.img-card2 h3 {
  text-align: center;
  margin-top: 5%;
}
.img-card2 p {
  text-align: center;
  padding: 10px;
}

/* make an appointment */

.appointment {
  padding: 5%;
  margin-bottom: 3%;
}
.appointment button {
  margin-top: 2%;
  border: white 1px solid;
  border-radius: 7px;
  padding: 0.7% 1.2%;
  color: white;
}

.appointment button:hover {
  background-color: white;
  color: rgb(0, 162, 255);
}

/* testimony */
.testimony {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.para {
  margin: 15% 0;
  padding: 10px;
}

.tester_new {
  padding: 20px 0 100px;
}
.tester_new hr {
  width: 100px;
  margin-bottom: 3%;
}
.tester_new .carousel-item p {
  color: #666;
  line-height: 28px;
  letter-spacing: 1px;
}
.tester_new .carousel-item hr {
  width: 100px;
  margin-bottom: 5%;
}
.tester_new .carousel-item h5 {
  font-weight: 700;
  margin-bottom: 0;
}
.tester_new .carousel-indicators li {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-image: url(man.png);
  background-position: center;
  background-size: cover;
  border: 5px solid #f1f2f3;
  opacity: 0.4;
  box-shadow: 0px 0px 10px 1px #ccc;
  margin: 0 10px;
  cursor: pointer;
}
.tester_new .carousel-indicators li:nth-child(2) {
  background-image: url(woman.png);
}
.tester_new .carousel-indicators li:nth-child(3) {
  background-image: url(man.png);
}
.tester_new .carousel-indicators .active {
  opacity: 1;
}
