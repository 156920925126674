.aboutusImage{
    width: 100%;
    height: 400px;
    object-fit: cover;
}

@media screen and (max-width:800px){
    .aboutusImage{
        width: 100%;
        height: auto;
    }
}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.82);
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: .5s ease;
}

.item {
    position: relative;
    overflow: hidden;
    margin: 0px 0;
}

.item:hover .overlay {
  bottom: 0;
  height: 100%;
  width: 100%;
}

.top-overlay {
    bottom: 100%;    
    height: 0;
}
.item:hover .top-overlay {
  bottom: 0;
}
.bottom-overlay {
    top: 100%;
}

.item:hover .bottom-overlay {
  top: 0;
}

.right-overlay {
    left: 100%;
    height: 100%;
}

.item:hover .right-overlay {
  left : 0;
}

.left-overlay {
    right: 100%;
    height: 100%;
    left:auto;
}

.item:hover .left-overlay {
  right : 0;
}
.fade-overlay {
    height: 100%;
    opacity: 0;
}

.item:hover .fade-overlay {
  opacity: 1;
}
.title-overlay {
    height: auto;
    top: auto;
    opacity: 0;
}
.item:hover .title-overlay {
    opacity: 1;
    height: auto;
    padding: 50px 0;
}
.text {
    color: white;
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

/* about us deatail */

.about{
	cursor: pointer;
	background-color: #ebe9e9;
	color: #28a745;
	padding-top: 20px;
	padding-bottom: 30px;
}
.about h1 {
	padding: 10px 0;
	margin-bottom: 20px;
}
.about h2 {
	opacity: .8;
    margin-top: 5px;
}
.about span {
	display: block;
	width: 100px;
	height: 100px;
	line-height: 100px;
	margin:auto;
	border-radius:50%;  
	font-size: 40px;
	color: #FFFFFF;
	opacity: 0.7;
	background-color: #ffc107;
	border: 2px solid #000000;

	webkit-transition:all .5s ease;
 	moz-transition:all .5s ease;
 	os-transition:all .5s ease;
 	transition:all .5s ease;

}
.about-item:hover span{
	opacity: 1;	
	border: 2px solid #000000;
	font-size: 42px;
	-webkit-transform: scale(1.1,1.1) rotate(360deg) ;
	-moz-transform: scale(1.1,1.1) rotate(360deg) ;
	-o-transform: scale(1.1,1.1) rotate(360deg) ;
	transform: scale(1.1,1.1) rotate(360deg) ;
}
.about-item:hover h2{
	opacity: 1;
	-webkit-transform: scale(1.1,1.1)  ;
	-moz-transform: scale(1.1,1.1)  ;
	-o-transform: scale(1.1,1.1)  ;
	transform: scale(1.1,1.1) ;
}
.about .lead{
	color: #000000;
	font-size: 15px;
	font-weight: bold;
    letter-spacing: 1px;
}

.imgAbout{
    width:100%;
    height:400px;
    object-fit: cover;
    
}