:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --gray: #4444bd;
    --green: #59ff22;
}
/* nav area */
.uppernav{
    color: var(--blue);
}
.uppernav span{
    color: gray;
}

.navbar {
    position: sticky !important;
}

.sticky-top{
    z-index: 1;
}
.AppointmentForm{
    text-align: center;
}
@media only screen and (max-width: 800px){
    .AppointmentForm{
        text-align: center;
        font-size: 30px;
    }
}

.imgCarousel{
    width: 100%;
    height: 500px;
    object-fit: cover;
}