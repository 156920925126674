:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --gray: #dededf;
}

a {
    color: #ffffff;
  }
  
  a:hover {
    color: #fec503;
    text-decoration: none;
  }
  
  ::selection {
    background: #fec503;
    text-shadow: none;
  }

  footer{
      padding: 5px;
      text-align: center;
  }
  
  ul.social-buttons {
    margin-bottom: 0;
  }
  
  ul.social-buttons li a:active,
  ul.social-buttons li a:focus,
  ul.social-buttons li a:hover {
    background-color: #fec503;
  }
  
  ul.social-buttons li a {
    font-size: 20px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
    border-radius: 100%;
    outline: 0;
    background-color: 28A745;
  }
  
  footer .quick-links {
    font-size: 90%;
    line-height: 40px;
    margin-bottom: 0;
    text-transform: none;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .copyright {
    color: white;
  }
  
  .fa-ellipsis-h {
    color: white;
    padding: 2rem 0;
  }
